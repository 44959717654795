<template>
<l-map ref="map" :style="{height: innerHeight + 'px'}" :zoom="zoom" :center="center" :options="{zoomControl: false, bounceAtZoomLimits: false}" :minZoom="7" @update:bounds="boundsChanged" @update:zoom="zoomChanged" @ready="mapReady" @update:center="centerChanged">
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker :lat-lng="userCoords" v-if="userCoords != null" :options="{zIndexOffset: 601}">
        <l-icon :icon-size="dynamicSizePin" :icon-url="'/osm/pin.png'" />
    </l-marker>
    <!--<v-marker-cluster :options="{spiderfyOnMaxZoom: false, showCoverageOnHover: false, zoomToBoundsOnClick: true, disableClusteringAtZoom: 13}">-->
        <l-marker :lat-lng="[poi.latitude,poi.longitude]" @click="poiClick(poi)" v-for="poi in drawnPois" :key="'poi-' + poi.id" @mouseover="getPoi(poi)">
            <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor" :icon-url="$TARGETS[poi.target_id]" />
            <l-tooltip :options="{direction: 'center',}"> {{ poiDetails == null ? '...' : poiDetails.name }}</l-tooltip>
        </l-marker>

    <!--</v-marker-cluster>-->

    <!-- Cluster Circles -->
     <l-marker :lat-lng="[cluster.geometry.coordinates[1], cluster.geometry.coordinates[0]]" v-for="cluster in drawnClusters" :key="'cluster-' + cluster.properties.id" :icon="cluster.properties.icon" @click="expandCluster(cluster)"/>

    <l-control-zoom v-if="controlsEnabled" position="bottomright"></l-control-zoom>
</l-map>
</template>

<script>
import Supercluster from 'supercluster';

import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlZoom,
    LTooltip,
} from 'vue2-leaflet';

import L from 'leaflet';
import {
    apiCall,
    baseUri
} from '../utils/ApiMiddleware';
//import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import {
    GlobalEventEmitter
} from '../utils/GlobalEventEmitter';
export default {
    name: 'openStreetMap',
    props: {
        controlsEnabled: {
            type: Boolean,
            default: true
        },

        poiClick: {
            type: Function,
            default: function () {}
        },

        pois: {
            type: Array,
            default: function () {
                return [];
            }
        },

        center: {
            type: Array,
            default: function () {
                return [45.9488798, 8.5512232];
            }
        },

        userCoords: {
            type: Array,
            default: function () {
                return null;
            }
        },

        innerHeight: {
            type: Number,
            default: 100,
            required: false
        }
    },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LControlZoom,
        LTooltip,
        //'v-marker-cluster': Vue2LeafletMarkerCluster
    },

    data() {
        return {
            L,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 15,
            markerLatLng: [45.9488798, 8.5512232],
            poiDetails: null,
            dynamicSizePin: [25, 25],
            newZoom: null,
            baseUri,

            drawnPois: [],
            drawnClusters: [],

            userCentered: null,
        }
    },

    methods: {
        centerChanged(e) {
            //this.center = e;
            this.calcUserCenterDelta(e);
        },

        expandCluster(cluster){
            // center it and zoom in
            if(!this.$refs.map){
                return;
            }
            this.$refs.map.mapObject.setView(new L.LatLng(cluster.geometry.coordinates[1], cluster.geometry.coordinates[0]), this.$refs.map.mapObject.getZoom() + 1);
        },

        boundsChanged(e) {
            this.drawElements(this.pois); // in this way we immediately recalculate clusters
            this.$emit('boundsChanged', e);
        },

        zoomChanged(e) {
            this.newZoom = e;
            this.dynamicSizePin = [e * 2, e * 2];
        },

        drawControls(){
            if(document.getElementById('center-user-btn') != null){
                return;
            }

            if(!this.controlsEnabled){
                return;
            }

            var el = document.createElement('a');
            el.id = 'center-user-btn';
            el.onclick = function(){
                GlobalEventEmitter.$emit('askForGPS');
            }
            el.className = 'leaflet-control-zoom-in';
            el.style.display = 'flex';
            el.style.justifyContent = 'center';
            el.style.alignItems = 'center';
            var img = document.createElement('img');
            img.src = require('../assets/icons/center.png');
            img.style.width = '24px';
            el.appendChild(img);
            var target = document.querySelector('.leaflet-control-zoom');
            target.insertBefore(el, target.firstChild);
        },

        mapReady() {
            this.drawControls();  

            this.drawElements(this.pois);
        },

        async getPoi(poi) {
            this.poiDetails = null;
            const response = await apiCall('GET', '/pois/' + poi.id);
            if (response.status == 200) {
                this.poiDetails = response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: 'Qualcosa è andato storto!',
                    text: 'Impossibile caricare il punto di interesse, riprova più tardi.',
                    color: 'danger',
                    position: 'top-right'
                })
            }
        },

        updateZoom(zoom) {
            this.newZoom = zoom;
        },

        drawElements(n){
            if(!this.$refs.map){
                return;
            }

            // Initialize Supercluster
            const index = new Supercluster({
                    log: false,
                    radius: 60,
                    extent: 256,
                    maxZoom: 17
                });

            // Create GeoJSON feature collection
            const geojson = {
                type: 'FeatureCollection',
                features: n.map(point => ({
                    type: 'Feature',
                    properties: { 
                        id: point.id,
                        target_id: point.target_id,
                    }, // Add your custom properties
                    geometry: {
                        type: 'Point',
                        coordinates: [point.longitude, point.latitude], // Longitude first!
                    }
                }))
            };

            console.log(geojson);

            // Load the points into the index
            index.load(geojson.features);

            // Get current map bounds
            const bounds = this.$refs.map.mapObject.getBounds();

            // Convert Leaflet bounds to Supercluster bounding box
            let bbox = [
                bounds.getWest(),
                bounds.getSouth(),
                bounds.getEast(),
                bounds.getNorth(),
            ];

            // make bbox 4x bigger to load clusters outside the current viewport
            bbox = [
                bbox[0] - (bbox[2] - bbox[0]),
                bbox[1] - (bbox[3] - bbox[1]),
                bbox[2] + (bbox[2] - bbox[0]),
                bbox[3] + (bbox[3] - bbox[1]),
            ];

            // Get clusters for the current bounding box and zoom level
            const zoom = this.$refs.map.mapObject.getZoom();
            const clusters = index.getClusters(bbox, zoom);

            // Update the state
            this.drawnClusters = clusters.filter(cluster => cluster.properties.cluster).map(cluster => {
                const count = cluster.properties.point_count;
                const size =
                    count < 100 ? 'small' :
                    count < 1000 ? 'medium' : 'large';
                const icon = L.divIcon({
                    html: `<div><span>${cluster.properties.point_count_abbreviated}</span></div>`,
                    className: `marker-cluster marker-cluster-${size}`,
                    iconSize: L.point(40, 40)
                });

                return {
                    type: 'Feature',
                    properties: {
                        id: cluster.id,
                        icon: icon,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [cluster.geometry.coordinates[0], cluster.geometry.coordinates[1]],
                    }
                }
            });
            this.drawnPois = clusters.filter(cluster => !cluster.properties.cluster).map(point => ({
                id: point.properties.id,
                latitude: point.geometry.coordinates[1],
                longitude: point.geometry.coordinates[0],
                target_id: point.properties.target_id,
            }));
        },

        async calcUserCenterDelta(e){
            if(!this.$refs.map){
                return;
            }

            // calc delta from user position to understand if user still almost centered and we follow it's movements
            if (this.userCoords != null) {
                const delta = Math.abs(this.userCoords[0] - e.lat) + Math.abs(this.userCoords[1] - e.lng);
                console.log('DELTA', delta);
                console.log('DELTA', this.$refs.map.mapObject.getZoom())
                // delta low and zoom not too far
                if (delta > 0.005 || this.$refs.map.mapObject.getZoom() < 14) {
                    this.userCentered = false;
                } else {
                    this.userCentered = true;
                }
            } else {
                this.userCentered = false;
            }
        }
    },

    created() {
        console.log('TARGETS ' +JSON.stringify(this.$TARGETS));
        GlobalEventEmitter.$on('setZoom', this.updateZoom)
    },
    beforeDestroy() {
        GlobalEventEmitter.$off('setZoom', this.updateZoom)
    },

    watch: {
        userCentered(n){
            console.log('USER STILL CENTERED', n);
            // change color of center button
            if (n) {
                document.getElementById('center-user-btn').style.backgroundColor = '#ffad63';
            } else {
                document.getElementById('center-user-btn').style.backgroundColor = 'white';
            }

            this.$emit('userCenteredChanged', n);
        },

        zoom(n) {
            console.log(n)
            this.dynamicSizePin = [25 * (n / 10), 25 * (n / 10)];
        },
        center(n) {
            if(!this.$refs.map){
                return;
            }
            this.$refs.map.mapObject.setView(new L.LatLng(n[0], n[1]), this.newZoom != null ? this.newZoom : this.zoom);
            if (this.newZoom != null) {
                this.zoom = this.newZoom;
                this.newZoom = null;
            }
        },

        pois: {
            handler(n) {
                this.drawElements(n);
            },
            immediate: true,
        },

        controlsEnabled(){
            this.drawControls();
        }

    },

    mounted() {

    },
    computed: {
        dynamicSize() {
            return [40.5, 46.5];
        },
        dynamicAnchor() {
            return [46.5 * 0.5, 0.86 * 40.5];
        },
    },
}
</script>

<style>
@import '~leaflet/dist/leaflet.css';
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style><style>
@media (max-width: 600px) {
    .leaflet-bottom {
        bottom: 50px;
    }
}
.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
	}
.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
	}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
	}
.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
	}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
	}
.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
	}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
	}
.marker-cluster span {
	line-height: 30px;
	}
</style>
